import React from "react"
import { graphql } from 'gatsby'

import PostLink from "../components/post-link"
import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = ({
                     data: {
                       allMarkdownRemark: { edges },
                     },
                   }) => {
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date)
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)
  return (
  <Layout>
    <Seo title="Home" />
    <h1 style={{
      fontSize: `1.75rem`,
    }}>Writing & Notes</h1>
    <div
      style={{fontSize: `1.25rem`}}
    >{Posts}</div>
  </Layout>
  )
}
export default IndexPage
export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
          }
        }
      }
    }
  }
`
