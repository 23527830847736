import React from "react"
import { Link } from "gatsby"
const PostLink = ({ post }) => (
  <div>
  <h3 style={{
    margin: `1.5rem 0 0 0`,
  }}>
    <Link to={post.frontmatter.path}>
      {post.frontmatter.title}
    </Link>
  </h3>
  <div style={{
    fontSize: `1rem`
  }}
  >{post.frontmatter.date}</div>
  </div>
)
export default PostLink
